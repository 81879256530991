import { NavLink } from "react-router-dom";
import Page from "../Page";
import InfinityBrandCarousel from "../../components/UI/InfinityBrandCarousel/InfinityBrandCarousel";

import classes from "./HomePage.module.scss";

const HomePage = () => {
  return (
    <>
      <Page>
        <div className={classes.home}>
          <div className={classes.home__about}>
            <div className={classes["home__about-text"]}>
              <p>
                <strong>Drukarnia "KAW"</strong> to firma powstała w 2006r.,
                mająca swoją siedzibę w Jeleniej Górze. U nas znajdziesz
                wszystko co związane z reklamą dla twojego biznesu – od
                wizytówek, przez ulotki, banery, billboardy, druk na szkle czy
                drewnie, a na oklejaniu samochodu skończywszy. W swojej pracy stawiamy na jakość i
                rozwój, dlatego też od wielu lat sukcesywnie powiększamy nasz
                park maszynowy, aby móc sprostać coraz to bardziej wymagającym
                klientom.
              </p>
              <p>
                <strong>
                  Dołącz do grona firm, które nam zaufały – postaw
                  na&nbsp;jakość!
                </strong>
              </p>
            </div>

            <div className={classes["home__about-brands"]}>
              <InfinityBrandCarousel />
            </div>
          </div>

        </div>
      </Page>

      <div className={classes.home__history}>
        <div className={classes["home__history-text"]}>
          <h2>Drukarnia KAW</h2>
          <p>
            Nasza firma powstała w 2006 roku pod nazwą Karkonoska Agencja
            Wydawnicza i od tego czasu przeszliśmy rebrending, choć cząstka
            pierwszego logo towarzyszy nam do dnia dzisiejszego. Ten kto nas zna
            od dawna wie, że ciągle stawiamy na rozwój, dlatego systematycznie –
            praktycznie co dwa lata zmieniamy lokalizację, aby pomieścić kolejne
            maszyny, które pojawiają się w naszym parku maszynowym.
          </p>
          <h2>od poligrafii ...</h2>
          <p>
            Początkowo nasza firma specjalizowała się w małej poligrafii, czyli&nbsp;
            <NavLink to="/oferta/wizytowki" title="Przejdź do strony informacyjnej o wizytówkach - Drukarnia Jelenia Góra">wizytówkach</NavLink>, <NavLink to="/oferta/ulotki" title="Przejdź do strony informacyjnej o ulotkach - Drukarnia Jelenia Góra">ulotkach</NavLink>, katalogach, ale doskonale wiecie, że nie
            mogliśmy na tym poprzestać, dlatego po kilku latach wprowadziliśmy
            do naszej oferty <NavLink to="/oferta/oklejanie-samochodu" title="Przejdź do strony informacyjnej o oklejaniu samochodu - Drukarnia Jelenia Góra">oklejanie samochodu</NavLink>, <NavLink to="/oferta/banery" title="Przejdź do strony informacyjnej o banerach - Drukarnia Jelenia Góra">benary</NavLink> czy siatki MESH, ale
            wówczas w jeszcze małych formatach. Ale i to okazało się za mało,
            więc zainwestowaliśmy w kolejne sprzęty i wprowadziliśmy druk
            wielkoformatowy – od tego czasu duże formaty nie są nam już
            straszne.
          </p>
          <h2>... do druku UV</h2>
          <p>
            A skoro nie lubimy się nudzić i ciągle się rozwijamy, to następnie
            postawiliśmy na innowacyjny <NavLink to="/oferta/druk-uv" title="Przejdź do strony informacyjnej o druku UV - Drukarnia Jelenia Góra">druk UV</NavLink>, czyli druk bezpośredni na
            powierzchniach płaskich. I tak oto rozwijamy się dla WAS – naszych
            klientów obecnych jak i przyszłych, abyście w jednym miejscu mogli
            znaleźć wszystko co związane z reklamą dla biznesu – od wizytówek,
            przez ulotki, banery, billboardy, a na oklejaniu samochodu skończywszy.
          </p>
        </div>
      </div>
    </>
  );
};

export default HomePage;
