import { useContext, useEffect, useState } from "react";

import ClassicMenuContext from "../context/classic-menu-bar-context";

import classes from "../ClassicMenuBar.module.scss";

const defaultClass = classes.menubar__button;
const activeClass = `${defaultClass} ${classes["menubar__button--active"]}`;

const ClassicMenuBarButton = () => {
  const { isMenuOpen, setIsMenuOpen } = useContext(ClassicMenuContext);
  const [menuButtonClass, setMenuButtonClass] = useState(defaultClass);

  useEffect(() => {
    if (isMenuOpen) {
      setMenuButtonClass(activeClass);
    } else {
      setMenuButtonClass(defaultClass);
    }
  }, [isMenuOpen]);

  const openMenuBar = () => {
    isMenuOpen ? setIsMenuOpen(false) : setIsMenuOpen(true);
  };

  return (
    <div className={menuButtonClass} onClick={openMenuBar}>
      <span></span>
    </div>
  );
};

export default ClassicMenuBarButton;
