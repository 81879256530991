import { NavLink } from "react-router-dom";
import Page from "../Page";
import classes from "./PricePage.module.scss";

const PricePage = () => {
  return (
    <>
      <Page>
        <h2>Cennik</h2>
        <p style={{textAlign:"center"}}>Podane ceny są cenami netto, należy doliczyć 23% VAT</p>
        <div className={classes["price-page"]}> 
          <div className={classes["price-page__item"]}> 
            <p style={{marginBottom:"0rem"}}><strong>1. Druk wielkoformatowy solwentowy (min. 2m<sup>2</sup>)</strong></p>
            <p>Więcej informacji o banerach - <NavLink to="/oferta/banery" title="Przejdź do strony informacyjnej o banerach - Drukarnia Jelenia Góra">kliknij tutaj</NavLink></p>
            <div className={classes["price-page__sell"]}>
                <div className={classes["price-page__sell-item"]}>
                    <div>
                        <p>baner laminowany</p>
                        <p>bez obróbki (sam druk)</p>
                    </div>
                    
                    <span>16 zł/m<sup>2</sup></span>
                </div>

                <div className={classes["price-page__sell-item"]}>
                    <div>
                        <p>baner laminowany</p>
                        <p>cięcie "na ostro" + oczkowanie co około 50cm</p>
                    </div>
                    
                    <span>18 zł/m<sup>2</sup></span>
                </div>

                <div className={classes["price-page__sell-item"]}>
                    <div>
                        <p>baner laminowany</p>
                        <p>zgrzewane boki i oczkowanie co około 50cm</p>
                    </div>
                    
                    <span>22 zł/m<sup>2</sup></span>
                </div>

                <div className={classes["price-page__sell-item"]}>
                    <div>
                        <p>baner powlekany (wzmacniany)</p>
                        <p>zgrzewane boki i oczkowanie co około 50cm</p>
                    </div>
                    
                    <span>25 zł/m<sup>2</sup></span>
                </div>

                <div className={classes["price-page__sell-item"]}>
                    <div>
                        <p>siatka mesh</p>
                        <p>zgrzewane boki i oczkowanie co około 50cm</p>
                    </div>
                    
                    <span>28 zł/m<sup>2</sup></span>
                </div>

                <div className={classes["price-page__sell-item"]}>
                    <div>
                        <p>baner odblaskowy</p>
                        <p>zgrzewane boki i oczkowanie co 50cm</p>
                    </div>
                    
                    <span>55 zł/m<sup>2</sup></span>
                </div>

                <div className={classes["price-page__sell-item"]}>
                    <div>
                        <p>baner blockout</p>
                        <p>szerokość: 3m</p>
                    </div>
                    
                    <span>40 zł/m<sup>2</sup></span>
                </div>

                <div className={classes["price-page__sell-item"]}>
                    <div>
                        <p>Oczkowanie co 30cm</p>
                    </div>
                    
                    <span>5 zł/m<sup>2</sup></span>
                </div>
            </div> 
          </div>

          <div className={classes["price-page__item"]}> 
            <p style={{marginBottom:"0rem"}}><strong>2. Druk UV na powierchniach płaskich (min. 1m<sup>2</sup>)</strong></p>
            <p>Więcej informacji o druku UV - <NavLink to="/oferta/druk-uv" title="Przejdź do strony informacyjnej o druku UV - Drukarnia Jelenia Góra">kliknij tutaj</NavLink></p>
            <div className={classes["price-page__sell"]} style={{marginBottom:"1.5rem"}}> 
                <div className={classes["price-page__sell-item"]}>
                    <div>
                        <p>Druk UV CMYK</p>
                    </div>
                    
                    <span>60 zł/m<sup>2</sup></span>
                </div>

                <div className={classes["price-page__sell-item"]}>
                    <div>
                        <p>Druk UV CMYK + White</p>
                    </div>
                    
                    <span>110 zł/m<sup>2</sup></span>
                </div>

                <div className={classes["price-page__sell-item"]}>
                    <div>
                        <p>Druk UV na szkle</p>
                        <p>CMYK + White + primer do szkła</p>
                    </div>
                    
                    <span>150 zł/m<sup>2</sup></span>
                </div>

                <div className={classes["price-page__sell-item"]}>
                    <div>
                        <p>docinanie na formatki</p>
                        <p>- mniejsze niż 1m<sup>2</sup> ( np. pcv ub dibond)</p>
                    </div>
                    
                    <span>od 50 zł/m<sup>2</sup></span>
                </div>

                <div className={classes["price-page__sell-item--large"]}>
                    <div>
                        <p>Druk UV trójwarstwowy</p>
                        <p>CMYK + WHITE + CMYK</p>
                        <p>nadruki na podświetlane lica kasetonów</p>
                    </div>
                    
                    <span>130 zł/m<sup>2</sup></span>
                </div>
            </div> 
            <p>Powyższe ceny dotyczą tylko wydruku UV, nie zawierają kosztu materiału, na którym drukujemy.</p>
          </div>

          <div className={classes["price-page__item"]}> 
            <p><strong>3. Druk eco-solventowy</strong></p>
            <div className={classes["price-page__sell"]}>
                <div className={classes["price-page__sell-item"]}>
                    <div>
                        <p>Folia monomerowa - błysk</p>
                    </div>
                    
                    <span>30 zł/m<sup>2</sup></span>
                </div>

                <div className={classes["price-page__sell-item"]}>
                    <div>
                        <p>Laminat monomerowy - błysk</p>
                    </div>
                    
                    <span>18 zł/m<sup>2</sup></span>
                </div>

                <div className={classes["price-page__sell-item"]}>
                    <div>
                        <p>Folia polimerowa - błysk</p>
                    </div>
                    
                    <span>45 zł/m<sup>2</sup></span>
                </div>

                <div className={classes["price-page__sell-item"]}>
                    <div>
                        <p>Laminat polimerowy - błysk</p>
                    </div>
                    
                    <span>25 zł/m<sup>2</sup></span>
                </div>

                <div className={classes["price-page__sell-item"]}>
                    <div>
                        <p>Folia wylewana - błysk</p>
                    </div>
                    
                    <span>90 zł/m<sup>2</sup></span>
                </div>

                <div className={classes["price-page__sell-item"]}>
                    <div>
                        <p>Laminat wylewany - błysk</p>
                    </div>
                    
                    <span>60 zł/m<sup>2</sup></span>
                </div>

                <div className={classes["price-page__sell-item"]}>
                    <div>
                        <p>Folia odblaskowa Oralight (5200)</p>
                    </div>
                    
                    <span>75 zł/m<sup>2</sup></span>
                </div>

                <div className={classes["price-page__sell-item"]}>
                    <div>
                        <p>Folia OWV</p>
                    </div>
                    
                    <span>50 zł/m<sup>2</sup></span>
                </div>

                <div className={classes["price-page__sell-item"]}>
                    <div>
                        <p>Blockout pet do rollup'ów</p>
                    </div>
                    
                    <span>60 zł/m<sup>2</sup></span>
                </div>

                <div className={classes["price-page__sell-item"]}>
                    <div>
                        <p>Papier plakatowy satynowy 150g</p>
                    </div>
                    
                    <span>40 zł/m<sup>2</sup></span>
                </div>

                <div className={classes["price-page__sell-item"]}>
                    <div>
                        <p>Docinanie papieru plakatowego</p>
                        <p>do mniejszych formatów</p>
                    </div>
                    
                    <span>od 25 zł/m<sup>2</sup></span>
                </div>

                <div className={classes["price-page__sell-item"]}>
                    <div>
                        <p>Tapeta samoprzylepna </p>
                        <p>"Linen ADH"</p>
                    </div>
                    
                    <span>60 zł/m<sup>2</sup></span>
                </div>

                <div className={classes["price-page__sell-item"]}>
                    <div>
                        <p>Plotowanie wydrukowanej folii</p>
                        <p>małe naklejki, stikery, etykiety</p>
                    </div>
                    
                    <span>30 zł/m<sup>2</sup></span>
                </div>

                <div className={classes["price-page__sell-item--large"]}>
                    <div>
                        <p>Tapeta "Shell"</p>
                        <p>winylowa na podkładzie flizelinowym</p>
                        <p>klejenie na klej do tapet</p>
                    </div>
                    
                    <span>50 zł/m<sup>2</sup></span>
                </div>
            </div> 
          </div>

          <div className={classes["price-page__item"]}> 
            <p><strong>4. Poligrafia - szybki druk cyfrowy i offsetowy</strong></p>
            <p style={{marginBottom:"1.5rem"}}>W ofercie posiadamy:&nbsp;
            <NavLink to="/oferta/wizytowki" title="Przejdź do strony informacyjnej o banerach - Drukarnia Jelenia Góra">wizytówki</NavLink>, <NavLink to="/oferta/ulotki" title="Przejdź do strony informacyjnej o banerach - Drukarnia Jelenia Góra">ulotki</NavLink>, <NavLink to="/oferta/plakaty" title="Przejdź do strony informacyjnej o banerach - Drukarnia Jelenia Góra">plakaty</NavLink>, teczki firmowe, katalogi szyte i klejone, <NavLink to="/oferta/kalendarze" title="Przejdź do strony informacyjnej o banerach - Drukarnia Jelenia Góra">kalendarze</NavLink> wiszące i stojące, notesy, papiery firmowe, pocztówki, etykiety, vouchery, zaproszenia itp. W&nbsp;sprawie wyceny prosimy o <NavLink to="/kontakt" title="Przejdź do strony kontaktowej - Drukarnia Jelenia Góra">kontakt</NavLink> na adres <a title="Adres email Drukarni KAW w Jeleniej Górze" href="mailto:biuro@reklamajg.pl">biuro@reklamajg.pl</a>.</p>
            <p style={{marginBottom:"0rem"}}>Opracowanie graficzne - od 100 zł netto. </p>
            <p>Końcowa cena opracowania projektu uzależniona jest od nakładu pracy. </p>
          </div>

          <div className={classes["price-page__item"]}> 
            <p><strong>5. Niestandardowe Usługi</strong></p>
            <p>Jeśli interesuje Cię usługa, której aktualnie nie widzisz w naszej ofercie, nie wahaj się skontaktować z naszą drukarnią w Jeleniej Górze. Chociaż dana opcja może nie być standardowo dostępna, istnieje możliwość, że jesteśmy w stanie ją zrealizować. Nasz zespół z przyjemnością rozważy Twoje potrzeby i postara się znaleźć rozwiązanie, które spełni Twoje oczekiwania. Napisz do nas na adres: <a title="Adres email Drukarni KAW w Jeleniej Górze" href="mailto:biuro@reklamajg.pl">biuro@reklamajg.pl</a>, a my sprawdzimy, jak możemy Ci pomóc.</p>
          </div>

          <div className={classes["price-page__item"]}> 
            <p style={{fontWeight:"700", fontStyle:"italic"}}>Przedstawiona oferta cenowa ma charakter informacyjny, nie stanowi oferty handlowej w rozumieniu Art.66 par.1 Kodeksu Cywilnego.</p>
          </div>
        </div>
      </Page>
    </>
  );
};

export default PricePage;
