import { NavLink } from "react-router-dom";
import Page from "../../Page";
import classes from "./../OfferPage.module.scss";

const OfferLeafletsPage = () => {
  return (
    <>
      <Page>
        <h2>Ulotki</h2>
        <p className={classes["offer-page"]} style={{marginTop: "0rem"}}>Ulotki to jedno z najbardziej popularnych narzędzi marketingowych, które od lat cieszy się niesłabnącą popularnością. Dzięki swojej prostocie i bezpośredniości, ulotki pozwalają na szybkie przekazanie kluczowych informacji o&nbsp;Twojej firmie, produkcie lub usłudze. W&nbsp;Jeleniej&nbsp;Górze oferujemy kompleksowe usługi w zakresie projektowania i&nbsp;druku ulotek, które wyróżnią Twoją reklamę na tle konkurencji. Niezależnie od tego, czy potrzebujesz ulotek promocyjnych, informacyjnych, czy eventowych, nasze rozwiązania są dopasowane do Twoich indywidualnych potrzeb.</p>
        <div className={classes["offer-page"]}> 
            <div className={classes["price-page__item"]}> 
                <p><strong>Ulotki Jednostronicowe – Szybkie i Skuteczne Przekazanie Informacji</strong></p>
                <p>Jednostronicowe ulotki to idealny wybór, jeśli chcesz przekazać najważniejsze informacje w prosty i przystępny sposób. Dzięki ich kompaktowemu formatowi, ulotki te są łatwe do rozdania, a ich treść jest natychmiast przyswajana przez odbiorców. W naszej drukarni w Jeleniej Górze oferujemy różne formaty ulotek jednostronicowych, które mogą być w pełni spersonalizowane pod kątem grafiki, kolorystyki i treści. Wysokiej jakości druk gwarantuje, że Twoje ulotki będą estetyczne i profesjonalne, co pozytywnie wpłynie na wizerunek Twojej marki.</p>
            </div>

            <div className={classes["price-page__item"]}> 
                <p><strong>Ulotki Składane – Więcej Miejsca na Twoją Reklamę</strong></p>
                <p>Jeśli potrzebujesz więcej miejsca na przedstawienie swojej oferty, ulotki składane są doskonałym rozwiązaniem. Pozwalają na umieszczenie większej ilości treści oraz zdjęć, co czyni je idealnymi do prezentacji bardziej złożonych informacji, takich jak opisy produktów, cenniki, czy mapki dojazdowe. W Jeleniej Górze zajmujemy się projektowaniem i drukiem ulotek składanych, które mogą być dostosowane do Twojej wizji. Oferujemy różne rodzaje składania – od prostych, po bardziej złożone, co pozwala na kreatywne zaprezentowanie Twojej oferty.</p>
            </div>

            <div className={classes["price-page__item"]}> 
                <p><strong>Ulotki Reklamowe – Wszechstronne Narzędzie Promocji</strong></p>
                <p>Ulotki reklamowe to wszechstronne narzędzie promocji, które pozwala na szybkie i efektywne dotarcie do potencjalnych klientów. Niezależnie od tego, czy potrzebujesz prostych ulotek do codziennej dystrybucji, czy eleganckich wersji na specjalne okazje, w Drukarni Wielkoformatowej KAW oferujemy pełen zakres usług – od projektu po druk. Nasze ulotki są wykonane z dbałością o każdy detal, co gwarantuje, że będą one skutecznym elementem Twojej strategii marketingowej.</p>
            </div>

            <div className={classes["price-page__item"]}> 
              <p style={{marginBottom:"0rem"}}>Dowiedz się więcej o naszych ulotkach i odkryj, jak mogą one wzmocnić Twoją reklamę w Jeleniej Górze. Orientacyjne ceny znajdziesz <NavLink to="/cennik" title="Przejdź do cennika - Drukarnia Jelenia Góra">tutaj</NavLink>. Znajdź naszą drukarnie za pomocą strony <NavLink to="/kontakt" title="Przejdź do strony kontaktowej - Drukarnia Jelenia Góra">kontakowej</NavLink> lub napisz bezpośrednio na adres: <a title="Adres email Drukarni KAW w Jeleniej Górze" href="mailto:biuro@reklamajg.pl">biuro@reklamajg.pl</a>.</p>
            </div>

            <div className={classes["price-page__item"]}> 
              <p>Powrót do <NavLink to="/oferta" title="Przejdź do oferty - Drukarnia Jelenia Góra">oferty</NavLink> naszej drukarni.</p>
            </div>
        </div>
      </Page>
    </>
  );
};

export default OfferLeafletsPage;
