import { NavLink } from "react-router-dom";
import Page from "../../Page";
import classes from "./../OfferPage.module.scss";

const OfferUVPage = () => {
  return (
    <>
      <Page>
        <h2>Druk UV na powierchniach płaskich</h2>
        <p className={classes["offer-page"]} style={{marginTop: "0rem"}}>Druk UV to innowacyjna technologia, która rewolucjonizuje sposób tworzenia materiałów reklamowych na różnych powierzchniach. W naszej drukarni w Jeleniej Górze wykorzystujemy druk UV do osiągania wyjątkowej jakości i trwałości wydruków na powierzchniach płaskich, takich jak szkło, drewno czy obrazy podświetlane. Dzięki zastosowaniu promieni UV do utwardzania tuszu, nasze materiały reklamowe charakteryzują się intensywnymi kolorami, odpornością na działanie czynników zewnętrznych oraz długotrwałą estetyką. Druk UV umożliwia precyzyjne odwzorowanie detali i zapewnia trwałość, co czyni go idealnym rozwiązaniem dla nowoczesnych projektów reklamowych i dekoracyjnych.</p>
        <div className={classes["offer-page"]}> 
            <div className={classes["price-page__item"]}> 
                <p><strong>Na czym możemy wykonać wydruk UV?</strong></p>
                <p>Drukujemy na płasko na materiałach własnych oraz wybranych materiałach powierzonych przez klienta. Wykonujemy nadruki na różnorodnych powierzchniach, takich jak szkło (np. panele do kuchni czy łazienki), PCV, HIPS, PCV kolorowe, dibond, plexa, sklejka, płyta MDF, PET, płyty aluminiowe, papier, karton, canvas oraz gotowe podobrazia. Nasze maksymalne pole robocze druku UV na płasko wynosi 1250 mm x 2500 mm, a wysokość materiału nie może przekraczać 40 mm. Dzięki temu możemy realizować zarówno małe, jak i większe projekty z wysoką precyzją i jakością.</p>
            </div>

            <div className={classes["price-page__item"]}> 
                <p><strong>Druk UV na Szkle – Elegancja i Trwałość</strong></p>
                <p>Druk UV na szkle oferuje wyjątkowe możliwości reklamowe. Dzięki technologii druku UV, nasze grafiki i napisy są trwałe, odporne na zarysowania i działanie czynników atmosferycznych. Druk UV na szkle pozwala na tworzenie eleganckich i nowoczesnych elementów reklamowych, które świetnie sprawdzą się zarówno w przestrzeniach komercyjnych, jak i prywatnych. Nasze usługi w Jeleniej Górze zapewniają wysoką jakość druku, który doskonale komponuje się z designem wnętrz i przyciąga wzrok klientów.</p>
            </div>

            <div className={classes["price-page__item"]}> 
                <p><strong>Druk UV na Drewnie – Naturalne Piękno z Wyrafinowaną Precyzją</strong></p>
                <p>Jeśli szukasz unikalnych rozwiązań reklamowych, druk UV na drewnie jest idealnym wyborem. Ta technologia pozwala na uzyskanie wyraźnych, żywych kolorów na naturalnej powierzchni drewna, zachowując jednocześnie jego charakterystyczną teksturę. Druk UV na drewnie jest doskonałym rozwiązaniem do tworzenia personalizowanych elementów dekoracyjnych, znaków informacyjnych czy prezentów reklamowych, które łączą w sobie naturalne piękno materiału z nowoczesną technologią druku.</p>
            </div>

            <div className={classes["price-page__item"]}> 
                <p><strong>Druk UV na Obrazach Podświetlanych – Imponująca Głębia</strong></p>
                <p>Dzięki technologii druku UV w trzech warstwach (CMYK + White + CMYK), nasze obrazy zyskują głębię i intensywność kolorów, które stają się jeszcze bardziej wyraziste, gdy są podświetlane. Ten rodzaj druku jest idealny do tworzenia efektownych tablic informacyjnych, reklam w punktach sprzedaży czy dekoracji ścian, które przyciągną uwagę i zapadną w pamięć. W naszej drukarni w Jeleniej Górze zapewniamy precyzyjne wykonanie i najwyższą jakość druku, aby Twoje projekty wyglądały profesjonalnie i efektownie.</p>
            </div>

            <div className={classes["price-page__item"]}> 
              <p style={{marginBottom:"0rem"}}>Dowiedz się więcej o druku UV i jak może on wzmocnić Twoją reklamę w Jeleniej Górze. Orientacyjne ceny znajdziesz <NavLink to="/cennik" title="Przejdź do cennika - Drukarnia Jelenia Góra">tutaj</NavLink>. Znajdź naszą drukarnie za pomocą strony <NavLink to="/kontakt" title="Przejdź do strony kontaktowej - Drukarnia Jelenia Góra">kontakowej</NavLink> lub napisz bezpośrednio na adres: <a title="Adres email Drukarni KAW w Jeleniej Górze" href="mailto:biuro@reklamajg.pl">biuro@reklamajg.pl</a>.</p>
            </div>

            <div className={classes["price-page__item"]}> 
              <p>Powrót do <NavLink to="/oferta" title="Przejdź do oferty - Drukarnia Jelenia Góra">oferty</NavLink> naszej drukarni.</p>
            </div>
        </div>
      </Page>
    </>
  );
};

export default OfferUVPage;
