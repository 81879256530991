import { NavLink } from "react-router-dom";
import Page from "../../Page";
import classes from "./../OfferPage.module.scss";

const OfferBannerPage = () => {
  return (
    <>
      <Page>
        <h2>Banery</h2>
        <p className={classes["offer-page"]} style={{marginTop: "0rem"}}>Banery reklamowe to jedno z najskuteczniejszych narzędzi promocyjnych. W&nbsp;naszej drukarni w&nbsp;Jeleniej&nbsp;Górze oferujemy wysokiej jakości banery, które doskonale sprawdzą się zarówno w kampaniach outdoorowych, jak i wewnętrznych. Dzięki solidnym materiałom i odporności na warunki atmosferyczne, nasze banery zapewniają Twojej firmie w Jeleniej Górze długotrwałą widoczność i skuteczną reklamę.</p>
        <div className={classes["offer-page"]}> 
            <div className={classes["price-page__item"]}> 
                <p><strong>Banery Odblaskowe - Maksymalna Widoczność</strong></p>
                <p>W Jeleniej Górze, gdzie ruch uliczny nie zwalnia nawet po zmroku, banery odblaskowe stanowią doskonałe rozwiązanie, aby Twoja reklama była widoczna przez całą dobę. W naszej drukarni w Jeleniej Górze produkujemy banery odblaskowe, które dzięki specjalnej powłoce odbijają światło, co sprawia, że reklama jest doskonale widoczna nawet w słabym oświetleniu. To idealny wybór dla firm, które chcą maksymalnie wykorzystać swoje możliwości reklamowe w Jeleniej Górze.</p>
            </div>

            <div className={classes["price-page__item"]}> 
                <p><strong>Banery Wzmacniane – Wytrzymałość na Lata</strong></p>
                <p>Jeśli szukasz reklamy, która przetrwa najtrudniejsze warunki pogodowe, nasze banery wzmacniane są idealnym wyborem. W Jeleniej Górze, gdzie pogoda może być kapryśna, banery wzmacniane, produkowane w naszej drukarni, zapewnią Twojej firmie długotrwałą i niezawodną promocję. Dzięki dodatkowym wzmocnieniom i solidnym materiałom, te banery są odporne na rozerwania i przetarcia, co czyni je doskonałym narzędziem reklamy w Jeleniej Górze.</p>
            </div>

            <div className={classes["price-page__item"]}> 
                <p><strong>Siatka Mesh – Lekkość i Przepuszczalność</strong></p>
                <p>Siatki mesh to innowacyjne rozwiązanie reklamowe. Dzięki perforowanej strukturze, nasze siatki mesh są odporne na silne podmuchy wiatru, co czyni je świetnym wyborem na duże powierzchnie reklamowe, takie jak fasady budynków. Wykonane w naszej drukarni w Jeleniej Górze, siatki mesh oferują lekkość i trwałość, zapewniając jednocześnie doskonałą widoczność reklamy i minimalne obciążenie konstrukcji. To idealne rozwiązanie dla firm, które chcą wyróżnić się na rynku lokalnym i zyskać przewagę w reklamie w Jeleniej Górze.</p>
            </div>

            <div className={classes["price-page__item"]}> 
              <p style={{marginBottom:"0rem"}}>Dowiedz się więcej o naszych banerach i odkryj, jak mogą one wzmocnić Twoją reklamę w Jeleniej Górze. Orientacyjne ceny znajdziesz <NavLink to="/cennik" title="Przejdź do cennika - Drukarnia Jelenia Góra">tutaj</NavLink>. Znajdź naszą drukarnie za pomocą strony <NavLink to="/kontakt" title="Przejdź do strony kontaktowej - Drukarnia Jelenia Góra">kontakowej</NavLink> lub napisz bezpośrednio na adres: <a title="Adres email Drukarni KAW w Jeleniej Górze" href="mailto:biuro@reklamajg.pl">biuro@reklamajg.pl</a>.</p>
            </div>

            <div className={classes["price-page__item"]}> 
              <p>Powrót do <NavLink to="/oferta" title="Przejdź do oferty - Drukarnia Jelenia Góra">oferty</NavLink> naszej drukarni.</p>
            </div>
        </div>
      </Page>
    </>
  );
};

export default OfferBannerPage;
