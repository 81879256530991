import { createContext, useState } from "react";

const ClassicMenuContext = createContext({
  isMenuOpen: undefined,
  setIsMenuOpen: (boolean) => {},
  activeItem: undefined,
  setActiveItem: (string) => {},
  isPageSwap: undefined,
  setIsPageSwap: (boolean) => {},
});

export const ClassicMenuContextProvider = (props) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isPageSwap, setIsPageSwap] = useState(false);
  const [activeItem, setActiveItem] = useState("Strona Główna");

  const setIsMenuOpenController = (boolean) => setIsMenuOpen(boolean);

  const setIsPageSwapController = (boolean) => setIsPageSwap(boolean);

  const setActiveItemController = (string) => setActiveItem(string);

  const context = {
    isMenuOpen: isMenuOpen,
    setIsMenuOpen: setIsMenuOpenController,
    activeItem: activeItem,
    setActiveItem: setActiveItemController,
    isPageSwap: isPageSwap,
    setIsPageSwap: setIsPageSwapController,
  };

  return (
    <ClassicMenuContext.Provider value={context}>
      {props.children}
    </ClassicMenuContext.Provider>
  );
};

export default ClassicMenuContext;
