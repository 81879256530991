// Import from React Project
import image_1 from "./../../../../assets/img/logos/alvi.png";
import image_2 from "./../../../../assets/img/logos/brw.png";
import image_3 from "./../../../../assets/img/logos/castorama.png";
import image_4 from "./../../../../assets/img/logos/hirschberger.png";
import image_5 from "./../../../../assets/img/logos/IDcustoms.png";
import image_6 from "./../../../../assets/img/logos/leroy.png";
import image_7 from "./../../../../assets/img/logos/natalii.png";


//Export to Classic Menu Bar
export const images = [
  image_1,
  image_2,
  image_3,
  image_4,
  image_5,
  image_6,
  image_7,
];

export const alt_text = "drukarnia jelenia góra - logo partnera #";
