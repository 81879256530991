import Page from "../Page";
import classes from "./OfferPage.module.scss";


import baner from "./../../assets/img/offer/baner.png";
import wizytowki from "./../../assets/img/offer/wizytowki.png";
import uv from "./../../assets/img/offer/uv.png";
import kalendarz from "./../../assets/img/offer/kalendarz.png";
import naklejki from "./../../assets/img/offer/naklejki.png";
import oklejanie from "./../../assets/img/offer/oklejanie_aut.png";
import ulotki from "./../../assets/img/offer/ulotki.png";
import roll from "./../../assets/img/offer/rollup.png";
import plakaty from "./../../assets/img/offer/plakaty.png";

import OfferGallery from "../../components/UI/OfferGallery/OfferGallery";

const OfferPage = () => {

    const data1 = [
        {
          name: "Banery",
          link: "/oferta/banery",
          src: baner,
        },
    
        {
          name: "Wizytówki",
          link: "/oferta/wizytowki",
          src: wizytowki,
        },
    
        {
          name: "Druk UV",
          link: "/oferta/druk-uv",
          src: uv,
        },

        
      ];

      const data2 = [
        {
          name: "Kalendarze",
          link: "/oferta/kalendarze",
          src: kalendarz,
        },
    
        {
          name: "Naklejki",
          link: "/oferta/naklejki",
          src: naklejki,
        },
    
        {
          name: "Oklejanie reklamowe samochodów",
          link: "/oferta/oklejanie-samochodu",
          src: oklejanie,
        },
      ];

      const data3 = [
        {
          name: "Ulotki",
          link: "/oferta/ulotki",
          src: ulotki,
        },
    
        {
          name: "Rollup'y",
          link: "/oferta/rollupy",
          src: roll,
        },
    
        {
          name: "Plakaty",
          link: "/oferta/plakaty",
          src: plakaty,
        },
      ];





  return (
    <>
      <Page>
        <h2>Oferta</h2>
        <p className={classes["offer-page"]} style={{textAlign:"center", marginTop: "0rem"}}>Poznaj nasze kompleksowe rozwiązania reklamowe dostępne w Jeleniej Górze, które&nbsp;pomogą&nbsp;Ci&nbsp;wyróżnić się na rynku i zbudować silny wizerunek firmy.</p>
        <div className={classes["offer-page"]}> 
            <div className={classes["price-page__item"]}> 
                <p><strong>Siła Napędowa Twojej Firmy</strong></p>
                <p>Dobra reklama to siła napędowa Twojej firmy. Dzięki naszej drukarni w Jeleniej Górze uzyskasz niebanalną i intrygującą reklamę, która wzbudzi emocje w każdym, kto ją ogląda. Wykorzystując naszą wiedzę i doświadczenie, stworzymy dla Ciebie różnorodne materiały reklamowe, takie jak efektowne banery, eleganckie wizytówki i praktyczne kalendarze. Nasze banery, wykonane z wysokiej jakości materiałów, idealnie nadają się do promocji na zewnątrz - tablice, budynki czy płoty, a wizytówki będą doskonałym sposobem na zaprezentowanie Twojej firmy w codziennych kontaktach.</p>
            </div>

            <OfferGallery data={data1} />

            <div className={classes["price-page__item"]}> 
                <p><strong>Potężne Narzędzie Budowania Marki</strong></p>
                <p>Dobrze dopasowana grafika to potężne narzędzie w budowaniu marki, kreowaniu wizerunku firmy oraz pozyskiwaniu nowych klientów. W naszej drukarni w Jeleniej Górze przykładamy szczególną wagę do precyzyjnego wykonania i nowoczesnego designu, dostosowanego do Twoich indywidualnych potrzeb i wizji. Oferujemy także usługi druku UV, które zapewniają trwałość i intensywność kolorów, idealne do zastosowań na różnych powierzchniach płaskich. Każdy element reklamy, od wizytówek po banery, będzie skutecznie wspierał rozwój Twojej firmy.</p>
            </div>

            <OfferGallery data={data2} />

            <div className={classes["price-page__item"]}> 
                <p><strong>Kluczowy Element Strategii Marketingowej</strong></p>
                <p>Reklama to nie tylko sposób na przyciągnięcie uwagi, ale także kluczowy element strategii marketingowej. W naszej drukarni w Jeleniej Górze oferujemy szeroki wachlarz usług, które pomogą Ci zbudować silną obecność na rynku lokalnym. Nasze banery, wizytówki, kalendarze i materiały drukowane w technologii UV są zaprojektowane z myślą o Twoich potrzebach i oczekiwaniach. Zapraszamy do zapoznania się z naszą pełną ofertą i odkrycia, jak nasze kompleksowe rozwiązania mogą wzmocnić wizerunek Twojej firmy. Sprawdź nasze produkty i przekonaj się, jak możemy pomóc w osiągnięciu sukcesu!</p>
            </div>

            <OfferGallery data={data3} />

            <div className={classes["price-page__item"]}> 
                <p><strong>Nasza Obietnica Jakości</strong></p>
                <p>Zapraszamy do zapoznania się z naszą ofertą. Jako lokalna drukarnia w Jeleniej Górze, dołożymy wszelkich starań, aby reklama, którą stworzymy, stała się Twoim atutem i silnym narzędziem w walce o klientów. Naszym celem jest zapewnienie Ci najwyższej jakości produktów, takich jak trwałe banery, estetyczne wizytówki, innowacyjne druki UV oraz funkcjonalne kalendarze. Dzięki naszym usługom Twoja reklama nie tylko spełni, ale przewyższy Twoje oczekiwania.</p>
            </div>
        </div>
      </Page>
    </>
  );
};

export default OfferPage;
