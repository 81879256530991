import { HashRouter, Route, Routes } from "react-router-dom";


import ScrollToTop from "./components/UI/ScrollToTop";

import Layout from "./components/Layout/Layout";
import HomePage from "./pages/home/HomePage";

import OfferPage from "./pages/offer/OfferPage";
import OfferBannerPage from "./pages/offer/sub/OfferBanerPage";
import OfferBusinessCardsPage from "./pages/offer/sub/OfferBusinessCardsPage";
import OfferUVPage from "./pages/offer/sub/OfferUVPage";
import OfferCalendarPage from "./pages/offer/sub/OfferCalendarPage";
import OfferStickersPage from "./pages/offer/sub/OfferStickersPage";
import OfferLeafletsPage from "./pages/offer/sub/OfferLeafletsPage";
import OfferRollupPage from "./pages/offer/sub/OfferRollupPage";
import OfferPostersPage from "./pages/offer/sub/OfferPostersPage";
import OfferCarWrappingPage from "./pages/offer/sub/OfferCarWrappingPage";

import PricePage from "./pages/price/PricePage";
import RodoPage from "./pages/rodo/RodoPage";
import ContactPage from "./pages/contact/ContactPage";

function App() {
  return (
    <>
      <HashRouter>
      <ScrollToTop/>
        <Routes>
          <Route exact path="/" element={<Layout />}>
            <Route index element={<HomePage />} />
            <Route path="oferta" element={<OfferPage />} />
                <Route path="oferta/banery" element={<OfferBannerPage />} />
                <Route path="oferta/wizytowki" element={<OfferBusinessCardsPage />} />
                <Route path="oferta/druk-uv" element={<OfferUVPage />} />
                <Route path="oferta/kalendarze" element={<OfferCalendarPage/>} />
                <Route path="oferta/naklejki" element={<OfferStickersPage />} />
                <Route path="oferta/oklejanie-samochodu" element={<OfferCarWrappingPage />} />
                <Route path="oferta/ulotki" element={<OfferLeafletsPage /> } />
                <Route path="oferta/rollupy" element={<OfferRollupPage />} />
                <Route path="oferta/plakaty" element={<OfferPostersPage />} />
            <Route path="cennik" element={<PricePage />} />
            <Route path="rodo" element={<RodoPage />} />
            <Route path="kontakt" element={<ContactPage />} />
          </Route>
        </Routes>
      </HashRouter>
    </>
  );
}

export default App;
