import { useEffect, useState } from "react";
import { images, alt_text } from "./properties/image-fade-slider-prop";

import classes from "./ImageFadeSlider.module.scss";

const ImageFadeSlider = () => {
  const [visibleIndex, setVisibleIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setVisibleIndex((visibleIndex + 1) % images.length);
    }, 4000);

    return () => clearInterval(interval);
  }, [visibleIndex]);

  return (
    <div className={classes.gallery}>
      <div className={classes.gallery__container}>
        {images.map((src, index) => (
          <div
            key={index}
            className={`${classes["gallery__item"]} ${
              index === visibleIndex ? classes["current"] : ""
            }`}
          >
            <img src={src} alt={`${alt_text}${index + 1}`} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default ImageFadeSlider;
