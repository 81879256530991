import { useContext } from "react";
import { NavLink } from "react-router-dom";
import { menu_logo } from "./../properties/classic-menu-bar-prop";

import ClassicMenuContext from "../context/classic-menu-bar-context";

import classes from "../ClassicMenuBar.module.scss";

const ClassicMenuBarLogo = () => {
  const menuContext = useContext(ClassicMenuContext);

  const itemClicked = (e) => {
    menuContext.setIsMenuOpen(false);
    menuContext.setActiveItem("Strona Główna");
  };

  return (
    <NavLink
      className={classes.menubar__logo}
      to={menu_logo.link}
      onClick={itemClicked}
    >
      <img src={menu_logo.src} alt={menu_logo.alt} />
    </NavLink>
  );
};

export default ClassicMenuBarLogo;
