import { useContext, useEffect, useState } from "react";
import { menu_item } from "../properties/classic-menu-bar-prop";

import ClassicMenuContext from "../context/classic-menu-bar-context";
import ClassicMenuBarMenuItem from "./ClassicMenuBarMenuItem";

import classes from "../ClassicMenuBar.module.scss";
import { useLocation } from "react-router";

const defaultClass = classes.menubar__menu;
const activeClass = `${defaultClass} ${classes["menubar__menu--active"]}`;

const ClassicMenuBarMenu = () => {
  const { isMenuOpen, setActiveItem } = useContext(ClassicMenuContext);
  const [menuClass, setMenuClass] = useState("");
  const location = useLocation();

  useEffect(() => {
    if (isMenuOpen) {
      setMenuClass(activeClass);
    } else {
      setMenuClass(defaultClass);
    }
  }, [isMenuOpen]);


  //Podświetlna dany element w zależności od ścieżki URL
  useEffect(() => {
    const path = location.pathname.split('/');
    const x = path[1];
    let p = ""

    if(x === "oferta") p = "Oferta";
    if(x === "kontakt") p = "Kontakt";
    if(x === "cennik") p = "Cennik";
    if(x === "") p = "Strona Główna";

    setActiveItem(p);

  }, [location]);

  const render = menu_item.map((item, index) => (
    <ClassicMenuBarMenuItem key={index} name={item.name} to={item.link} />
  ));

  return (
    <div className={menuClass}>
      <nav className={classes["menubar__menu-box"]}>{render}</nav>
    </div>
  );
};

export default ClassicMenuBarMenu;
