import { NavLink } from "react-router-dom";
import Page from "../../Page";
import classes from "./../OfferPage.module.scss";

const OfferPostersPage = () => {
  return (
    <>
      <Page>
        <h2>Plakaty</h2>
        <p className={classes["offer-page"]} style={{marginTop: "0rem"}}>Plakaty to ponadczasowy i efektywny sposób na dotarcie do szerokiego grona odbiorców. Idealnie sprawdzają się w przestrzeni publicznej, biurach, na eventach, czy w punktach sprzedaży, przyciągając wzrok i przekazując najważniejsze informacje o Twojej firmie, produkcie lub wydarzeniu. Nasza drukarnia w Jeleniej Górze oferuje profesjonalne usługi projektowania i druku plakatów w różnych formatach, zapewniając wysoką jakość wykonania i intensywne, trwałe kolory.</p>
        <div className={classes["offer-page"]}> 
            <div className={classes["price-page__item"]}> 
                <p><strong>Plakaty Reklamowe – Widoczność i Efektowność</strong></p>
                <p>Plakaty reklamowe to doskonały wybór, jeśli zależy Ci na szybkim i skutecznym przekazie. Dzięki swojej dużej powierzchni plakaty pozwalają na przedstawienie grafiki i treści w atrakcyjny sposób, który przyciąga uwagę. W Jeleniej Górze zajmujemy się projektowaniem i drukiem plakatów reklamowych, które mogą być umieszczane w miejscach o dużym natężeniu ruchu, takich jak przystanki, centra handlowe czy uliczne słupy ogłoszeniowe. Nasze plakaty to idealne narzędzie promocyjne dla każdej firmy.</p>
            </div>

            <div className={classes["price-page__item"]}> 
                <p><strong>Plakaty Eventowe – Informacja i Promocja w Jednym</strong></p>
                <p>Plakaty eventowe to idealna forma promocji wydarzeń takich jak koncerty, konferencje, wystawy czy premiery. Projektujemy plakaty, które nie tylko zawierają kluczowe informacje o wydarzeniu, ale również przyciągają wzrok i wzbudzają zainteresowanie. W Jeleniej Górze oferujemy plakaty eventowe w różnych rozmiarach i stylach, dostosowane do charakteru każdego wydarzenia. Dzięki naszym plakatom Twój event zyska profesjonalną oprawę i przyciągnie uwagę uczestników.</p>
            </div>

            <div className={classes["price-page__item"]}> 
                <p><strong>Plakaty Dekoracyjne – Sztuka i Reklama</strong></p>
                <p>Plakaty dekoracyjne to doskonały sposób na estetyczne i kreatywne wykorzystanie przestrzeni. Są chętnie stosowane w biurach, lokalach usługowych i domach, a dzięki pięknym projektom stanowią atrakcyjną ozdobę wnętrz. W Jeleniej Górze oferujemy plakaty dekoracyjne na wysokiej jakości papierze, które mogą być personalizowane pod kątem kolorystyki, motywów i stylu. To nie tylko reklama, ale również sposób na wprowadzenie klimatu i charakteru do przestrzeni.</p>
            </div>

            <div className={classes["price-page__item"]}> 
              <p style={{marginBottom:"0rem"}}>Dowiedz się więcej o naszych plakatach i odkryj, jak mogą one wzmocnić Twoją reklamę w Jeleniej Górze. Orientacyjne ceny znajdziesz <NavLink to="/cennik" title="Przejdź do cennika - Drukarnia Jelenia Góra">tutaj</NavLink>. Znajdź naszą drukarnie za pomocą strony <NavLink to="/kontakt" title="Przejdź do strony kontaktowej - Drukarnia Jelenia Góra">kontakowej</NavLink> lub napisz bezpośrednio na adres: <a title="Adres email Drukarni KAW w Jeleniej Górze" href="mailto:biuro@reklamajg.pl">biuro@reklamajg.pl</a>.</p>
            </div>

            <div className={classes["price-page__item"]}> 
              <p>Powrót do <NavLink to="/oferta" title="Przejdź do oferty - Drukarnia Jelenia Góra">oferty</NavLink> naszej drukarni.</p>
            </div>
        </div>
      </Page>
    </>
  );
};

export default OfferPostersPage;
