import { NavLink } from "react-router-dom";
import Page from "../Page";
import classes from "./ContactPage.module.scss";

import phone from "../../assets/img/icons/phone.png";
import map from "../../assets/img/icons/map.png";
import email from "../../assets/img/icons/email.png";

const ContactPage = () => {
  return (
    <>
      <Page>
        <div className={classes.contact}>
          <div className={classes.contact__ctc}>
            <h2 className={classes.contact__header}>Kontakt</h2>
            <p className={classes.contact__description}>Skontaktuj się z nami, aby zamówić wysokiej jakości&nbsp; <NavLink to="/oferta/banery" title="Przejdź do strony informacyjnej o banerach - Drukarnia Jelenia Góra">banery</NavLink>, <NavLink to="/oferta/wizytowki" title="Przejdź do strony informacyjnej o wizytówkach - Drukarnia Jelenia Góra">wizytówki</NavLink>, <NavLink to="/oferta/kalendarze" title="Przejdź do strony informacyjnej o kalendarzach - Drukarnia Jelenia Góra">kalendarze</NavLink> i inne materiały reklamowe, które nie tylko wyróżnią Twoją firmę na tle konkurencji, ale także przyciągną uwagę potencjalnych klientów dzięki starannie dobranym materiałom, precyzyjnemu wykonaniu i nowoczesnemu designowi, dostosowanemu do Twoich indywidualnych potrzeb i wizji. Drukarnia w Jeleniej Górze oferuje kompleksowe usługi, a nasze reklamy pomogą Twojej firmie zaistnieć na rynku lokalnym i zdobyć zaufanie klientów.</p>
          </div>

          <div className={classes.contact__info}>
            <h2 className={classes.contact__header}>Drukarnia Wielkoformatowa KAW</h2> 
            <div className={classes['contact__info-container']}>
              <div className={classes['contact__info-single']}>
                <img src={map} alt="Iknona pineski" />
                <div>
                  <p>ul. Karola Miarki 42</p>
                  <p>58-500 Jelenia Góra</p>
                </div>
              </div>
              <div className={classes['contact__info-single']}>
                <img src={phone} alt="Ikona telefonu" />
                <p><a title="Numer telefonu Drukarni KAW w Jeleniej Górze" href="tel:887-105-777">887-105-777</a></p>
              </div>
              <div className={classes['contact__info-single']}>
                <img src={email} alt="Ikona koperty" />
                <p><a title="Adres email Drukarni KAW w Jeleniej Górze" href="mailto:biuro@reklamajg.pl">biuro@reklamajg.pl</a></p>
              </div>
            </div>

            <div className={classes['contact__info-open']}>
              <p>Biuro czynne: Poniedziałek-Piątek | Godziny otwarcia: 9-16</p>
            </div>
          </div>

          <div className={classes.contact__map}>
            <p>Skorzystaj z mapy, żeby bez trudu znaleźć naszą drukarnię w Jeleniej Górze i zobaczyć, jak łatwo do nas dotrzeć. Znajdziesz nas w dogodnej lokalizacji, która umożliwi szybki dojazd i osobiste omówienie Twoich projektów reklamowych.</p>
            <iframe title="googleMap" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d10066.916690576336!2d15.7124543!3d50.8917227!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xfb50c8e6fb90e17d!2sDrukarnia%20Wielkoformatowa%20KAW!5e0!3m2!1spl!2spl!4v1637342841437!5m2!1spl!2spl" width="" height="450" style={{border:0, marginBottom:"1.5rem"}} allowfullscreen="" loading="lazy"></iframe>
            <p>Niezależnie od tego, czy potrzebujesz banerów, wizytówek, kalendarzy, czy innych materiałów, nasza drukarnia w Jeleniej Górze jest gotowa, aby spełnić Twoje oczekiwania. Zapraszamy do odwiedzenia nas na miejscu, aby osobiście przekonać się o jakości naszych usług i omówić szczegóły zamówienia.</p>
          
          
          </div>
          
     
        </div>
      </Page>
    </>
  );
};

export default ContactPage;
