import Page from "../Page";
import classes from "./RodoPage.module.scss";

const RodoPage = () => {
  return (
    <>
      <Page>
        <h2>Polityka Prywatności</h2>
        <div className={classes["rodo-page"]}> 
          <div className={classes["rodo-page__item"]}> 
            <p><strong>1. Administrator danych osobowych</strong></p>
            <p>
            Administratorem danych osobowych jest firma "Drukarnia Wielkoformatowa "KAW" Paulina Ciupka" z siedzibą na ul. Karola Miarki 42, 58-500 Jelenia Góra, NIP: 611-106-46-67
              (dalej: „my” lub „nasza firma”). Wszelkie pytania
              dotyczące ochrony danych osobowych można
              kierować na adres e-mail: <a title="Adres email Drukarni KAW w Jeleniej Górze" href="mailto:biuro@reklamajg.pl">biuro@reklamajg.pl</a>.</p>
          </div>

          <div className={classes["rodo-page__item"]}> 
            <p><strong>2. Wtyczka Facebook Page Plugin</strong></p>
            <p>
            Na naszej stronie internetowej korzystamy z wtyczki Facebook Page Plugin, 
            która umożliwia użytkownikom interakcję z naszą stroną na Facebooku bezpośrednio z naszej witryny.</p>
            <p>
            Wtyczka może zbierać następujące dane: adres ip użytkownika, informacje o przeglądarce internetowej, datę i godzinę odwiedzin na 
            naszej stronie oraz informacje o interakcjach użytkownika z wtyczką - polubienia i udostępnienia. Dane te mogą być przesyłane do Facebooka i przetwarzane zgodnie z Polityką Prywatności 
            Facebooka.</p>

            <p>Polityka dostępna pod adresem: <a rel="noreferrer" href="https://www.facebook.com/privacy/policy" target="_blank">https://www.facebook.com/privacy/policy</a>.</p>
          </div>

          <div className={classes["rodo-page__item"]}> 
            <p><strong>3. Cel Przetwarzania Danych</strong></p>
            <p>
            Zbierane dane są przetwarzane w celu umożliwienia interakcji użytkowników z naszą stroną na Facebooku oraz w celu analizowania statystyk dotyczących korzystania z naszej strony internetowej. 
            Dane te mogą być również wykorzystywane w celach marketingowych, w tym do personalizacji treści wyświetlanych na Facebooku.</p>
          </div>

          <div className={classes["rodo-page__item"]}> 
            <p><strong>4. Podstawa Prawna Przetwarzania Danych</strong></p>
            <p>
            Podstawą prawną przetwarzania danych przez naszą firmę jest uzasadniony interes polegający na promowaniu naszej działalności i analizowaniu statystyk dotyczących korzystania z naszej strony internetowej (art. 6 ust. 1 lit. f RODO).</p>
          </div>

          <div className={classes["rodo-page__item"]}> 
            <p><strong>5. Pliki Cookie</strong></p>
            <p>
            Nasza strona internetowa nie wykorzystuje plików cookie, z wyjątkiem tych, które mogą być używane przez wtyczkę Facebook Page Plugin. Pliki te mogą być wykorzystywane przez Facebooka w celach analitycznych i marketingowych. Użytkownicy mogą zarządzać swoimi preferencjami dotyczącymi plików cookie bezpośrednio w ustawieniach swojej przeglądarki.</p>
          </div>

          <div className={classes["rodo-page__item"]}> 
            <p><strong>6. Kontakt</strong></p>
            <p>
            W razie jakichkolwiek pytań lub wątpliwości dotyczących niniejszej Polityki Prywatności, prosimy o kontakt na adres e-mail: <a title="Adres email Drukarni KAW w Jeleniej Górze" href="mailto:biuro@reklamajg.pl">biuro@reklamajg.pl</a>.</p>
          </div>

          <div className={classes["rodo-page__item"]}> 
            <p><strong>7. Zmiany w Polityce Prywatności</strong></p>
            <p>
            Zastrzegamy sobie prawo do wprowadzania zmian w niniejszej Polityce Prywatności. Wszelkie zmiany będą publikowane na tej stronie.</p>
          </div>
        
        </div>


      </Page>
    </>
  );
};

export default RodoPage;
