// Import from React Project
import logo from "./../../../../assets/img/company_logo.png";

//Export to Classic Menu Bar
export const menu_logo = {
  link: "/",
  src: logo,
  alt: "logo drukarnia jelenia góra",
};

export const menu_item = [
  {
    link: "/",
    name: "Strona Główna",
  },
  {
    link: "/oferta",
    name: "Oferta",
  },
  {
    link: "/cennik",
    name: "Cennik",
  },
  {
    link: "/kontakt",
    name: "Kontakt",
  },
];
