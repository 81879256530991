import { useContext, useEffect, useState } from "react";
import ClassicMenuContext from "../context/classic-menu-bar-context";
import classes from "../ClassicMenuBar.module.scss";

const defaultClass = classes.menubar__background;
const activeClass = `${defaultClass} ${classes["menubar__background--active"]}`;

const ClassicMenuBarBackground = () => {
  const { isMenuOpen } = useContext(ClassicMenuContext);

  const [menuBackgroundClass, setMenuBackgroundClass] = useState(defaultClass);

  useEffect(() => {
    if (isMenuOpen) {
      setMenuBackgroundClass(activeClass);
    } else {
      setMenuBackgroundClass(defaultClass);
    }
  }, [isMenuOpen]);

  return <div className={menuBackgroundClass} />;
};

export default ClassicMenuBarBackground;
