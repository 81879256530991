import { NavLink } from "react-router-dom";
import Page from "../../Page";
import classes from "./../OfferPage.module.scss";

const OfferStickersPage = () => {
  return (
    <>
      <Page>
        <h2>Naklejki</h2>
        <p className={classes["offer-page"]} style={{marginTop: "0rem"}}>Naklejki to jedno z najbardziej wszechstronnych narzędzi reklamowych, które z powodzeniem wykorzystywane są w różnych branżach. Niezależnie od tego, czy potrzebujesz małych, subtelnych naklejek do oznakowania produktów, czy dużych naklejek do dekoracji witryn sklepowych, nasza drukarnia w Jeleniej Górze oferuje szeroki wachlarz rozwiązań, które dopasujemy do Twoich potrzeb. Dzięki możliwości personalizacji, naklejki stają się efektywnym sposobem na promocję Twojej marki, przyciągając wzrok i zwiększając rozpoznawalność wśród klientów.</p>
        <div className={classes["offer-page"]}> 
            <div className={classes["price-page__item"]}> 
                <p><strong>Duże Naklejki – Wyrazistość i Przyciąganie Uwagi</strong></p>
                <p>Duże naklejki to doskonałe rozwiązanie, jeśli chcesz wyróżnić swoją reklamę na dużych powierzchniach, takich jak witryny sklepowe. W Jeleniej Górze oferujemy druk dużych naklejek w różnych formatach i kształtach, które można dostosować do specyfiki Twojego projektu. Wyraziste kolory, wysokiej jakości materiały i precyzyjne wykończenie gwarantują, że Twoja reklama będzie widoczna i trwała, skutecznie przyciągając uwagę przechodniów i potencjalnych klientów.</p>
            </div>

            <div className={classes["price-page__item"]}> 
                <p><strong>Małe Naklejki – Subtelne, ale Skuteczne</strong></p>
                <p>Małe naklejki to niepozorne, ale niezwykle skuteczne narzędzie marketingowe, które znajduje szerokie zastosowanie w wielu dziedzinach. Mogą być używane do oznakowania produktów, jako elementy dekoracyjne, czy nawet jako zamiennik tradycyjnych etykiet. W naszej drukarni w Jeleniej Górze oferujemy małe naklejki w różnych kształtach, kolorach i wykończeniach, które można w pełni spersonalizować zgodnie z Twoimi potrzebami. Dzięki wysokiej jakości druku i trwałym materiałom, naklejki te doskonale sprawdzą się w każdej sytuacji, pozostając estetycznym i funkcjonalnym elementem Twojej reklamy.</p>
            </div>

            <div className={classes["price-page__item"]}> 
              <p style={{marginBottom:"0rem"}}>Dowiedz się więcej o naszych naklejkach i odkryj, jak mogą one wzmocnić Twoją reklamę w Jeleniej Górze. Orientacyjne ceny znajdziesz <NavLink to="/cennik" title="Przejdź do cennika - Drukarnia Jelenia Góra">tutaj</NavLink>. Znajdź naszą drukarnie za pomocą strony <NavLink to="/kontakt" title="Przejdź do strony kontaktowej - Drukarnia Jelenia Góra">kontakowej</NavLink> lub napisz bezpośrednio na adres: <a title="Adres email Drukarni KAW w Jeleniej Górze" href="mailto:biuro@reklamajg.pl">biuro@reklamajg.pl</a>.</p>
            </div>

            <div className={classes["price-page__item"]}> 
              <p>Powrót do <NavLink to="/oferta" title="Przejdź do oferty - Drukarnia Jelenia Góra">oferty</NavLink> naszej drukarni.</p>
            </div>
        </div>
      </Page>
    </>
  );
};

export default OfferStickersPage;
