import { NavLink } from "react-router-dom";
import classes from "./ClassicFooterBar.module.scss";
import img from "../../../assets/img/kaw_scroll_logo_white.png"

const year = new Date().getFullYear();

const ClassicFooterBar = () => {
  return (
    <>
      <div className={classes.media}>
        <div className={classes.media__container}>
          <div className={classes.media__fbc}>
            <div className="fb-page" data-href="https://www.facebook.com/DrukarniaKAW" data-tabs="" data-width="" data-height="" data-small-header="false" data-adapt-container-width="true" data-hide-cover="false" data-show-facepile="true"><blockquote cite="https://www.facebook.com/DrukarniaKAW" className="fb-xfbml-parse-ignore"><a href="https://www.facebook.com/DrukarniaKAW">Drukarnia KAW</a></blockquote></div>
          </div>
          <img src={img} alt="" />
        </div>
      </div>



      <footer className={classes.footer}>
        <div className={classes.footer__container}>
          <div className={classes.footer__company}>
            <div>&copy; {year} Drukarnia Wielkoformatowa KAW</div>
            <div>
              <span>Kalendarze, Banery, Wizytówki, Poligrafia, Druk UV</span>
            </div>
          </div>

          <div className={classes.footer__rodo}>
            <NavLink to="/rodo">Polityka Prywatności</NavLink>
          </div>

          <div className={classes.footer__design}>
            Design by{" "}
            <a target="_blank" rel="noreferrer" href="https://bozek.website">
              Bozek.Website
            </a>
          </div>
        </div>
      </footer>
    </>
  );
};

export default ClassicFooterBar;
