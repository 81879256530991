import { NavLink } from "react-router-dom";
import Page from "../../Page";
import classes from "./../OfferPage.module.scss";

const OfferCalendarPage = () => {
  return (
    <>
      <Page>
        <h2>Kalendarz</h2>
        <p className={classes["offer-page"]} style={{marginTop: "0rem"}}>Kalendarze to nie tylko narzędzie do organizacji czasu, ale również skuteczny nośnik reklamy, który pozostaje w zasięgu wzroku przez cały rok. W naszej drukarni w Jeleniej Górze oferujemy szeroki wybór kalendarzy, od trójdzielnych, przez spiralowane, po plakatowe, które można w pełni spersonalizować pod kątem grafiki, układu i treści. Każdy kalendarz to doskonała okazja, aby Twoja marka była widoczna każdego dnia, zarówno w biurach, jak i w domach Twoich klientów.</p>
        <div className={classes["offer-page"]}> 
            <div className={classes["price-page__item"]}> 
                <p><strong>Kalendarze Trójdzielne – Praktyczne i Przejrzyste</strong></p>
                <p>Kalendarze trójdzielne to klasyczne rozwiązanie, które cieszy się niesłabnącą popularnością w biurach i firmach. Dzięki trzem oddzielnym bloczkom z miesiącami, umożliwiają one szybkie i wygodne planowanie na przyszłość, bez konieczności przewracania stron. Nasza drukarnia w Jeleniej Górze oferuje kalendarze trójdzielne, które mogą zostać spersonalizowane, aby skutecznie promować Twoją firmę. Wybór odpowiedniej grafiki, logotypu i kolorystyki sprawia, że kalendarz staje się nie tylko narzędziem do organizacji, ale również efektywnym nośnikiem reklamowym, który przez cały rok przypomina o Twojej marce.</p>
            </div>

            <div className={classes["price-page__item"]}> 
                <p><strong>Kalendarze Jednodzielne – Elegancja w Minimalistycznej Formie</strong></p>
                <p>Jeśli szukasz prostego i eleganckiego sposobu na promocję, kalendarze jednodzielne mogą być idealnym wyborem. Te minimalistyczne kalendarze, dostępne w naszej drukarni w Jeleniej Górze, doskonale sprawdzają się w małych przestrzeniach biurowych lub w domu. Dzięki dużemu kalendarium są czytelne i łatwe w użytkowaniu, a jednocześnie stanowią subtelny element dekoracyjny. Dodatkowo, możliwość personalizacji pozwala na dostosowanie kalendarza do indywidualnych potrzeb, co czyni go praktycznym i estetycznym prezentem reklamowym.</p>
            </div>

            <div className={classes["price-page__item"]}> 
                <p><strong>Kalendarze Plakatowe – Duży Format, Wielki Wpływ</strong></p>
                <p>Dla tych, którzy preferują większe formaty, kalendarze plakatowe są doskonałym wyborem. Nasza drukarnia w Jeleniej Górze oferuje kalendarze plakatowe, które wyróżniają się dużą powierzchnią reklamową i pozwalają na umieszczenie obszernych grafik, zdjęć czy informacji o firmie. Tego typu kalendarze są idealne do zawieszenia w przestrzeniach publicznych, biurach czy salach konferencyjnych, gdzie mogą przyciągać uwagę przez cały rok. Kalendarz plakatowy to nie tylko narzędzie do planowania, ale również efektywna forma reklamy wizualnej, która eksponuje markę w wielkim stylu.</p>
            </div>

            <div className={classes["price-page__item"]}> 
              <p style={{marginBottom:"0rem"}}>Dowiedz się więcej o naszych kalendarzach i odkryj, jak mogą one wzmocnić Twoją reklamę w Jeleniej Górze. Orientacyjne ceny znajdziesz <NavLink to="/cennik" title="Przejdź do cennika - Drukarnia Jelenia Góra">tutaj</NavLink>. Znajdź naszą drukarnie za pomocą strony <NavLink to="/kontakt" title="Przejdź do strony kontaktowej - Drukarnia Jelenia Góra">kontakowej</NavLink> lub napisz bezpośrednio na adres: <a title="Adres email Drukarni KAW w Jeleniej Górze" href="mailto:biuro@reklamajg.pl">biuro@reklamajg.pl</a>.</p>
            </div>

            <div className={classes["price-page__item"]}> 
              <p>Powrót do <NavLink to="/oferta" title="Przejdź do oferty - Drukarnia Jelenia Góra">oferty</NavLink> naszej drukarni.</p>
            </div>
        </div>
      </Page>
    </>
  );
};

export default OfferCalendarPage;
