import { Outlet } from "react-router";

import ClassicMenuBar from "./ClassicMenuBar/ClassicMenuBar";
import ClassicFooterBar from "./ClassicFooterBar/ClassicFooterBar";
import ImageFadeSlider from "./ImageFadeSlider/ImageFadeSlider";
import { useEffect } from "react";



const Layout = () => {

  useEffect(() => {
    // Dodaj skrypt Facebooka do dokumentu
    if (!window.FB) {
      const script = document.createElement('script');
      script.src = 'https://connect.facebook.net/pl_PL/sdk.js#xfbml=1&version=v20.0';
      script.async = true;
      script.defer = true;
      script.crossOrigin = 'anonymous';
      script.nonce = 'ncTivkgq';
      document.body.appendChild(script);
  
      // Gdy skrypt jest załadowany, inicjuj wtyczkę FB
      script.onload = () => {
        window.FB.XFBML.parse();
      };
    } else {
      window.FB.XFBML.parse();
    }
  }, []);




  return (
    <>
      <ClassicMenuBar />
      <ImageFadeSlider />
      <Outlet />
      <ClassicFooterBar />
    </>
  );
};

export default Layout;
