import { ClassicMenuContextProvider } from "./context/classic-menu-bar-context";

import ClassicMenuBarLogo from "./elements/ClassicMenuBarLogo";
import ClassicMenuBarButton from "./elements/ClassicMenuBarButton";
import ClassicMenuBarBackground from "./elements/ClassicMenuBarBackground";
import ClassicMenuBarMenu from "./elements/ClassicMenuBarMenu";

import classes from "./ClassicMenuBar.module.scss";

const ClassicMenuBar = () => {
  return (
    <div className={classes.menubar}>
      <div className={classes.menubar__container}>
        <ClassicMenuContextProvider>
          <ClassicMenuBarLogo />
          <ClassicMenuBarButton />
          <ClassicMenuBarBackground />
          <ClassicMenuBarMenu />
        </ClassicMenuContextProvider>
      </div>
    </div>
  );
};

export default ClassicMenuBar;
