import { images, alt_text } from "./properties/infinity-brand-carousel-prop";

import classes from "./InfinityBrandCarousel.module.scss";

const InfinityBrandCarousel = () => {
  const render = images.map((item, index) => (
    <div key={index} className={classes["infinity-brand-carousel__slide"]}>
      <img src={item} alt={`${alt_text}${index + 1}`} />
    </div>
  ));

  return (
    <div className={classes["infinity-brand-carousel"]}>
      <div className={classes["infinity-brand-carousel__slider"]}>
        {render}
        {render}
      </div>
    </div>
  );
};

export default InfinityBrandCarousel;
