import { NavLink } from "react-router-dom";
import Page from "../../Page";
import classes from "./../OfferPage.module.scss";

const OfferBusinessCardsPage = () => {
  return (
    <>
      <Page>
        <h2>Wizytówki</h2>
        <p className={classes["offer-page"]} style={{marginTop: "0rem"}}>Wizytówki są kluczowym elementem budowania profesjonalnego wizerunku firmy. To nie tylko praktyczne narzędzie wymiany kontaktów, ale także pierwszy krok w budowaniu relacji biznesowych. Nasza drukarnia w Jeleniej Górze oferuje eleganckie, spersonalizowane wizytówki, które podkreślą charakter Twojej firmy i wyróżnią Cię na tle konkurencji. Dzięki starannemu wykonaniu i wysokiej jakości materiałom, nasze wizytówki robią doskonałe pierwsze wrażenie i pozostają w pamięci na długo.</p>
        <div className={classes["offer-page"]}> 
            <div className={classes["price-page__item"]}> 
                <p><strong>Wizytówki Premium – Elegancja i Profesjonalizm</strong></p>
                <p>Wizytówki premium to idealny wybór dla tych, którzy chcą się wyróżnić. W naszej drukarni oferujemy wizytówki premium, które są drukowane na najwyższej jakości papierze, z możliwością dodatkowego uszlachetnienia, takiego jak laminowanie, tłoczenie czy lakierowanie punktowe. Te wyjątkowe detale sprawiają, że Twoja reklama w Jeleniej Górze zyska jeszcze większą moc oddziaływania, a Twoja wizytówka stanie się niezapomniana.</p>
            </div>

            <div className={classes["price-page__item"]}> 
                <p><strong>Wizytówki Laminowane – Trwałość i Wysoka Jakość</strong></p>
                <p>Dzięki dodatkowej warstwie ochronnej, laminowanie sprawia, że wizytówki są bardziej odporne na zniszczenia, jednocześnie nadając im wyspecjalizowaną jakość wykończenia. W naszej drukarni w Jeleniej Górze oferujemy różne opcje laminowania, które podkreślą charakter Twojej firmy i zapewnią, że Twoja reklama w Jeleniej Górze pozostanie niezapomniana.</p>
            </div>

            <div className={classes["price-page__item"]}> 
                <p><strong>Wizytówki z Tłoczeniem – Wyjątkowy Efekt i Wyrazistość</strong></p>
                <p>Aby jeszcze bardziej wyróżnić swoją firmę na tle konkurencji w Jeleniej Górze, wizytówki z tłoczeniem są doskonałym wyborem. Tłoczenie dodaje wizytówkom wyjątkowej tekstury i trójwymiarowego efektu, co sprawia, że są one nie tylko eleganckie, ale także bardziej wyraziste w dotyku. W naszej drukarni w Jeleniej Górze oferujemy różne techniki tłoczenia, które podkreślą istotne elementy projektu i nadadzą Twoim wizytówkom wyjątkowy charakter, wspierając Twoją reklamę.</p>
            </div>

            <div className={classes["price-page__item"]}> 
              <p style={{marginBottom:"0rem"}}>Dowiedz się więcej o naszych wizytówkach i innych materiałach reklamowych, które możemy dla Ciebie przygotować - <NavLink to="/cennik" title="Przejdź do cennika - Drukarnia Jelenia Góra">cennik</NavLink>. Znajdź naszą drukarnie za pomocą strony <NavLink to="/kontakt" title="Przejdź do strony kontaktowej - Drukarnia Jelenia Góra">kontakowej</NavLink> lub napisz bezpośrednio na adres: <a title="Adres email Drukarni KAW w Jeleniej Górze" href="mailto:biuro@reklamajg.pl">biuro@reklamajg.pl</a>.</p>
            </div>

            <div className={classes["price-page__item"]}> 
              <p>Powrót do <NavLink to="/oferta" title="Przejdź do oferty - Drukarnia Jelenia Góra">oferty</NavLink> naszej drukarni.</p>
            </div>
        </div>
      </Page>
    </>
  );
};

export default OfferBusinessCardsPage;
